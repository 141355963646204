import i18n from "@/libs/i18n";
import Vue from "vue";
import store from "@/store";

/* eslint-disable import/prefer-default-export */
/**
 * Returns the collective's URL.
 * @param {*} collective The collective
 * @returns The URL.
 */
export function getCollectiveUrl(collective, params = "") {
  if(!collective){
    return null;
  }
  return (
    process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
      "{subdomain}",
      collective.subdomain || "app",
    ).replace("{slug}", collective.slug) + params
  );
}

export function selectLangFromCommunity(community) {
  const lang = Vue.$cookies.get("i18n_redirected") || i18n.locale || "en"; 
  if (community != null && !community.availableLanguages?.includes(lang) && !Vue.$cookies.get("i18n_redirected") ) {
    if (!community.availableLanguages?.includes(community.languageCode)) {
      i18n.locale = community.availableLanguages?.[0];
      store.dispatch("setLocale", community.availableLanguages?.[0]);
      Vue.$cookies.set("i18n_redirected", i18n.locale);
    } else {
      store.dispatch("setLocale", community.languageCode);
      i18n.locale = community.languageCode;
      Vue.$cookies.set("i18n_redirected", i18n.locale);
      if (lang == null) {
        Vue.$cookies.set("i18n_redirected", community.languageCode);
        store.dispatch("setLocale", i18n.locale);
      }
    }
  } else {
    store.dispatch("setLocale", lang);

    return i18n.locale || "en";
  }
}
